import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import tickImage from '../assets/tick.svg';
import nopImage from '../assets/nop.svg';
import cloud20 from '../assets/cloud/20.svg';
import cloud50 from '../assets/cloud/50.svg';
import cloud80 from '../assets/cloud/80.svg';
import arrow from '../assets/near_me_FILL1_wght400_GRAD0_opsz48.svg';
import { InfoCircle, Eye, Cloud, Wind, CloudDrizzle, Drop } from "iconsax-react";
import { Divider, Tooltip } from "@nextui-org/react";
import { TbTemperature } from "react-icons/tb";
import { MdDewPoint } from "react-icons/md";
import { Popover, PopoverTrigger, PopoverContent } from "@nextui-org/react";

const goodCloudCoverThreshold = 30;
const consecutiveGoodHoursRequired = 2;

const TonightComponent = () => {
    const { t } = useTranslation();
    const [latitude, setLatitude] = useState(parseFloat(localStorage.getItem('latitude')) || 52);
    const [longitude, setLongitude] = useState(parseFloat(localStorage.getItem('longitude')) || -1.5);
    const [unitTemp, setUnitTemp] = useState(localStorage.getItem('tempUnit') || '°C');
    const [unitWind, setUnitWind] = useState(localStorage.getItem('windUnit') || 'km/h');
    const [stargazingDescription, setStargazingDescription] = useState('');
    const [isGenerated, setIsGenerated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [browserLanguage, setBrowserLanguage] = useState('');
    const [ShowInfoSeeing, setShowInfoSeeing] = useState(false);
    const [ShowInfoWind, setShowInfoWind] = useState(false);
    const [openMeteoData, setOpenMeteoData] = useState(null);
    const [unit, setUnit] = useState(localStorage.getItem('unit') || 'metric');
    const [forceReload, setForceReload] = useState(false);

    const defaultSettings = {
        latitude: 47,
        longitude: -1,
        cityName: "Noirmoutier-en-l'Île",
        model: "best_match",
    };

    Object.entries(defaultSettings).forEach(([key, value]) => {
        if (!localStorage.getItem(key)) {
            localStorage.setItem(key, value);
        }
    });

    const [storedModel, setstoredModel] = useState(localStorage.getItem('defaultModel') || 'best_match');

    const fetchOpenMeteoData = useCallback(async () => {
        setOpenMeteoData(null);

        localStorage.removeItem('openMeteoData');
        localStorage.removeItem('openMeteoCacheTimestamp');

        const cachedOpenMeteoData = localStorage.getItem('openMeteoData');
        const cacheTimestamp = localStorage.getItem('openMeteoCacheTimestamp');

        if (cachedOpenMeteoData && cacheTimestamp) {
            const now = new Date().getTime();
            const cacheDuration = 60 * 60 * 1000;
            if (now - cacheTimestamp < cacheDuration) {
                setOpenMeteoData(JSON.parse(cachedOpenMeteoData));
                return;
            }
        }

        try {
            let openMeteoUrl = `https://customer-api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&current=temperature_2m,relative_humidity_2m,cloud_cover,wind_speed_10m,wind_direction_10m,precipitation_probability,dew_point_2m&hourly=precipitation_probability,dew_point_2m,temperature_2m,relative_humidity_2m,cloud_cover,cloud_cover_low,cloud_cover_mid,cloud_cover_high,wind_speed_10m,wind_direction_10m&daily=sunrise,sunset&timezone=auto&forecast_days=14&&models=${storedModel}&apikey=s8yKtXHBxEzlxk47`;

            if (unit === 'imperial') {
                openMeteoUrl += `&temperature_unit=fahrenheit&wind_speed_unit=mph`;
            }

            const response = await fetch(openMeteoUrl);
            const data = await response.json();
            setOpenMeteoData(data);

            localStorage.setItem('openMeteoData', JSON.stringify(data));
            localStorage.setItem('openMeteoCacheTimestamp', new Date().getTime());
        } catch (error) {
            console.error('Error fetching Open Meteo data:', error);
        }
    }, [latitude, longitude, unit]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newLatitude = parseFloat(localStorage.getItem('latitude'));
            const newLongitude = parseFloat(localStorage.getItem('longitude'));

            if (latitude !== newLatitude || longitude !== newLongitude) {
                setLatitude(newLatitude);
                setLongitude(newLongitude);
                setForceReload(true);
            }
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [latitude, longitude]);

    useEffect(() => {
        const cachedOpenMeteoData = localStorage.getItem('openMeteoData');
        const cacheTimestamp = localStorage.getItem('openMeteoCacheTimestamp');

        if (cachedOpenMeteoData && cacheTimestamp) {
            const now = new Date().getTime();
            const cacheDuration = 60 * 60 * 1000;
            if (now - cacheTimestamp < cacheDuration) {
                setOpenMeteoData(JSON.parse(cachedOpenMeteoData));
                return;
            }
        }

        fetchOpenMeteoData();
    }, [latitude, longitude, unit, forceReload, fetchOpenMeteoData]);

    useEffect(() => {
        if (forceReload) {
            fetchOpenMeteoData();
            setForceReload(false);
        }
    }, [forceReload, fetchOpenMeteoData]);

    useEffect(() => {
        const language = navigator.language || 'en';
        setBrowserLanguage(language);
    }, []);

    useEffect(() => {
        const handleStorageChange = () => {
            setLatitude(parseFloat(localStorage.getItem('latitude')) || 0);
            setLongitude(parseFloat(localStorage.getItem('longitude')) || 0);
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const generateForecastData = () => {
        if (!openMeteoData || !openMeteoData.hourly || !Array.isArray(openMeteoData.hourly.time)) {
            return [];
        }
        return openMeteoData.hourly.time.map((time, index) => ({
            dateTime: new Date(time),
            hour: new Date(time).getHours(),
            clouds: openMeteoData.hourly.cloud_cover?.[index] || 0,
            clouds_low: openMeteoData.hourly.cloud_cover_low?.[index] || 0,
            clouds_mid: openMeteoData.hourly.cloud_cover_mid?.[index] || 0,
            clouds_high: openMeteoData.hourly.cloud_cover_high?.[index] || 0,
            temperature: openMeteoData.hourly.temperature_2m?.[index] || null,
            windSpeed: openMeteoData.hourly.wind_speed_10m?.[index] || null,
            windDirection: openMeteoData.hourly.wind_direction_10m?.[index] || null,
            humidity: openMeteoData.hourly.relative_humidity_2m?.[index] || null,
            dew_point_2m: openMeteoData.hourly.dew_point_2m?.[index] || null,
            precipitation_probability: openMeteoData.hourly.precipitation_probability?.[index] || null,
        }));
    };

    const forecastData = generateForecastData();

    const currentDate = new Date();
    let sunsetTime = new Date(openMeteoData?.daily?.sunset[0]);
    sunsetTime.setHours(sunsetTime.getHours() + 2);
    let sunriseTime = new Date(openMeteoData?.daily?.sunrise[1]);
    sunriseTime.setHours(sunriseTime.getHours() - 2);

    const offsetHours = currentDate.getTimezoneOffset() / 60;
    let tonightSunset = new Date(openMeteoData?.daily?.sunset[0]);
    tonightSunset.setHours(tonightSunset.getHours() + 2 - offsetHours);
    let tonightSunrise = new Date(openMeteoData?.daily?.sunrise[1]);
    tonightSunrise.setHours(tonightSunrise.getHours() - 2 - offsetHours);

    if (currentDate > sunsetTime && currentDate < sunriseTime) {
        sunsetTime = new Date(openMeteoData.daily.sunset[0]);
        sunriseTime = new Date(openMeteoData.daily.sunrise[1]);
    }

    const nightForecastData = forecastData.filter(({ dateTime }) =>
        dateTime >= sunsetTime && dateTime <= sunriseTime
    );

    const getBestObservationTimeRange = (data) => {
        let observationStartTime = null;
        let observationEndTime = null;
        let consecutiveGoodHours = 0;
        let lowestCloudCover = Infinity;
        let extendedEndTime = null;

        for (let i = 0; i < data.length; i++) {
            const hourlyData = data[i];
            const { hour, clouds } = hourlyData;

            if (clouds <= goodCloudCoverThreshold) {
                if (consecutiveGoodHours === 0) observationStartTime = hour;
                consecutiveGoodHours++;
                lowestCloudCover = Math.min(lowestCloudCover, clouds);
                extendedEndTime = hour;

                if (consecutiveGoodHours >= consecutiveGoodHoursRequired) {
                    observationEndTime = extendedEndTime;
                }
            } else {
                if (observationEndTime) break;
                consecutiveGoodHours = 0;
                observationStartTime = null;
                observationEndTime = null;
                lowestCloudCover = Infinity;
            }
        }

        return {
            timeRange: observationStartTime && observationEndTime
                ? { start: observationStartTime, end: observationEndTime }
                : null,
            lowestCloudCover: lowestCloudCover === Infinity ? null : lowestCloudCover,
        };
    };

    const bestObservationInfo = getBestObservationTimeRange(nightForecastData);

    const hasConsecutiveGoodHours = (hourlyData, threshold = 30, requiredConsecutiveHours = 2) => {
        let consecutiveGoodHours = 0;
        for (let i = 0; i < hourlyData.length; i++) {
            if (hourlyData[i].clouds <= threshold) {
                consecutiveGoodHours++;
                if (consecutiveGoodHours >= requiredConsecutiveHours) {
                    return true;
                }
            } else {
                consecutiveGoodHours = 0;
            }
        }
        return false;
    };

    const dayForecastData = forecastData.filter(({ dateTime }) =>
        dateTime >= sunsetTime && dateTime <= sunriseTime
    );
    const isObservable = hasConsecutiveGoodHours(dayForecastData);

    useEffect(() => {
        const currentTime = Date.now();
        const storedStargazingDescription = localStorage.getItem('stargazingDescription');
        const storedLastFetchedTime = localStorage.getItem('lastFetchedTime');

        if (storedStargazingDescription && storedLastFetchedTime && (currentTime - parseInt(storedLastFetchedTime, 10) <= 3600000)) {
            setStargazingDescription(storedStargazingDescription);
            setIsLoading(false);
            return;
        }

        if (!isObservable) {
            setStargazingDescription(t("Stargazing is not optimal tonight."));
            setIsLoading(false);
            return;
        }

        if (!openMeteoData) return;

        const fetchData = async () => {
            const newStargazingDescription = "Generated description";
            setStargazingDescription(newStargazingDescription);
            setIsGenerated(true);
            localStorage.setItem('stargazingDescription', newStargazingDescription);
            localStorage.setItem('lastFetchedTime', currentTime.toString());
            setIsLoading(false);
        };

        fetchData();
    }, [openMeteoData, isObservable]);

    const extremeCloudCover = Math.max(...nightForecastData.map(item => item.clouds));
    const displayCloudCover = bestObservationInfo.lowestCloudCover ?? extremeCloudCover;

    const getCloudCoverBackground = (cloudCover) => {
        if (cloudCover >= 80) return `url(${cloud80})`;
        if (cloudCover >= 50) return `url(${cloud50})`;
        if (cloudCover >= 20) return `url(${cloud20})`;
        return '';
    };

    const calculateNightlyAverage = (data, key) => {
        const values = data.map((item) => item[key]).filter((value) => value !== null);
        if (values.length === 0) return null;
        const sum = values.reduce((acc, value) => acc + value, 0);
        return Math.round((sum / values.length) * 10) / 10;
    };

    const nightlyTemperature = Math.floor(calculateNightlyAverage(nightForecastData, "temperature"));
    const nightlyHumidity = Math.floor(calculateNightlyAverage(nightForecastData, "humidity"));
    const nightlyWindSpeed = Math.floor(calculateNightlyAverage(nightForecastData, "windSpeed"));
    const nightlydewpoint = Math.floor(calculateNightlyAverage(nightForecastData, "dew_point_2m"));
    const maxPrecipitationProbability = Math.max(...nightForecastData.map(data => data.precipitation_probability));

    const calculateWindDirectionAverage = (data) => {
        const windDirections = data.map((item) => item.windDirection).filter((value) => value !== null);
        if (windDirections.length === 0) return null;
        const x = windDirections.reduce((acc, direction) => acc + Math.cos((direction * Math.PI) / 180), 0);
        const y = windDirections.reduce((acc, direction) => acc + Math.sin((direction * Math.PI) / 180), 0);
        const averageDirection = Math.atan2(y, x) * (180 / Math.PI);
        return Math.round((averageDirection + 360) % 360);
    };

    const convertWindDirectionToNSEW = (degrees) => {
        if (degrees === null || isNaN(degrees)) return 'N/A';
        const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
        const index = Math.floor((degrees + 22.5) / 45) % 8;
        return directions[index];
    };

    const nightlyWindDirection = calculateWindDirectionAverage(nightForecastData);
    const windDirectionText = convertWindDirectionToNSEW(nightlyWindDirection);

    return (
        <div className='p-4' >
            <div tabIndex="0">
                {!openMeteoData ? (
                    <div className="ContainerSkeleton"></div>
                ) : (
                    isObservable ? (
                        <div className="badgeGoodnight font-medium">
                            <img src={tickImage} alt="Good for stargazing" />
                            {isLoading ? (
                                <p className="m-0">Let me analyze...</p>
                            ) : (
                                <p className="m-0">{stargazingDescription}</p>
                            )}
                        </div>
                    ) : (
                        <div className="badgeBadnight">
                            <img src={nopImage} alt="Bad for stargazing" />
                            <p className="m-0">{t('badforecast')}</p>
                        </div>
                    )
                )}
            </div>
            <div tabIndex="0" className="mt-3 ContainerTonightCloud bg-gray-900 p-3 rounded-t-md" style={{ backgroundImage: getCloudCoverBackground(displayCloudCover) }}>
                <div className="flex items-center gap-1">
                    <Cloud size={16} className="text-gray-500" />
                    <h2 className="text-gray-500">{t('cloudcover')}</h2>
                </div>
                <div>
                    <h1 className="text-center">{displayCloudCover} %</h1>
                </div>
            </div>
            <Divider className="bg-gray-800" />
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-3 mt-3">
                <div tabindex="0" className="cursor-pointer bg-gray-900 p-3 rounded-md flex flex-col gap-2">
                    <div tabindex="0">
                        <Popover backdrop="opaque">
                            <PopoverTrigger>
                                <div className="flex gap-1 items-center">
                                    <Eye size={16} className="text-gray-500" />
                                    <h2>Seeing</h2>
                                    <InfoCircle className="text-gray-500" size={18} />
                                </div>
                            </PopoverTrigger>
                            <PopoverContent className="max-w-64 purple-dark p-4">
                                {t('infoseeing')}
                            </PopoverContent>
                        </Popover>
                    </div>
                    <div>
                        <h1>
                            {!openMeteoData ? (
                                <div className="ContainerSkeleton" style={{ width: '60px', height: '14px', marginTop: '30px' }}></div>
                            ) : (
                                <span>{nightlyWindSpeed}<font style={{ color: 'var(--white)', fontSize: '20px' }}> {unitWind}</font></span>
                            )}
                        </h1>
                    </div>
                </div>
                <div tabindex="0" className="cursor-pointer bg-gray-900 p-3 rounded-md flex flex-col gap-2">
                    <Popover backdrop="opaque">
                        <PopoverTrigger>
                            <div tabindex="0" className="flex gap-1 items-center">
                                <Wind size={16} className="text-gray-500" />
                                <h2>{t('wind')}</h2>
                                <InfoCircle className="text-gray-500" size={18} />
                            </div>
                        </PopoverTrigger>
                        <PopoverContent className="max-w-64 purple-dark p-4">
                            Wind at 10m.
                        </PopoverContent>
                    </Popover>
                    <div className="flex items-center gap-2">
                        <div>
                            <h1>
                                {!openMeteoData ? (
                                    <div className="ContainerSkeleton" style={{ width: '60px', height: '14px', marginTop: '30px' }}></div>
                                ) : (
                                    <span>{nightlyWindSpeed}<font style={{ color: 'var(--white)', fontSize: '20px' }}> {unitWind}</font></span>
                                )}
                            </h1>
                        </div>
                        <div>
                            {!openMeteoData ? (
                                <div className="ContainerSkeleton" style={{ width: '60px', height: '14px', marginTop: '30px' }}></div>
                            ) : (
                                <div style={{ display: 'flex', alignItems: 'center', borderRadius: '10px' }}>
                                    <img src={arrow} style={{ transform: `rotate(${nightlyWindDirection + 180}deg)`, width: '22px' }} alt="wind direction" />
                                    <div><p style={{ color: 'var(--white)' }}>{windDirectionText}</p></div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div tabindex="0" className="bg-gray-900 p-3 rounded-md flex flex-col gap-2">
                    <div tabindex="0" className="flex gap-1 items-center">
                        <Drop variant="Linear" className="text-gray-500" size={16} />
                        <h2>{t('humidity')}</h2>
                    </div>
                    <div>
                        <h1>
                            {!openMeteoData ? (
                                <div className="ContainerSkeleton" style={{ width: '60px', height: '14px', marginTop: '30px' }}></div>
                            ) : (
                                <span>{nightlyHumidity}<font style={{ color: 'var(--white)', fontSize: '20px' }}> %</font></span>
                            )}
                        </h1>
                    </div>
                </div>
                <div tabindex="0" className="bg-gray-900 p-3 rounded-md flex flex-col gap-2">
                    <div tabindex="0" className="flex gap-1 items-center">
                        <TbTemperature className="text-gray-500" size={16} />
                        <h2>{t('temperature')}</h2>
                    </div>
                    <div>
                        <h1>
                            {!openMeteoData ? (
                                <div className="ContainerSkeleton" style={{ width: '60px', height: '14px', marginTop: '30px' }}></div>
                            ) : (
                                <span>{nightlyTemperature}<font style={{ color: 'var(--white)' }}> °</font></span>
                            )}
                        </h1>
                    </div>
                </div>
                <div tabindex="0" className="bg-gray-900 p-3 rounded-md flex flex-col gap-2">
                    <div tabindex="0" className="flex gap-1 items-center">
                        <MdDewPoint className="text-gray-500" size={16} />
                        <h2>{t('dewpoint')}</h2>
                    </div>
                    <div>
                        <h1>
                            {!openMeteoData ? (
                                <div className="ContainerSkeleton" style={{ width: '60px', height: '14px', marginTop: '30px' }}></div>
                            ) : (
                                <span>{nightlydewpoint}<font style={{ color: 'var(--white)' }}> °</font></span>
                            )}
                        </h1>
                    </div>
                </div>
                <div tabindex="0" className="bg-gray-900 p-3 rounded-md flex flex-col gap-2">
                    <div tabindex="0" className="flex gap-1 items-start">
                        <div>
                            <CloudDrizzle size={16} className="text-gray-500" />
                        </div>
                        <h2>{t('probabilityprecipitation')}</h2>
                    </div>
                    <div>
                        <h1>
                            {!openMeteoData ? (
                                <div className="ContainerSkeleton" style={{ width: '60px', height: '14px', marginTop: '30px' }}></div>
                            ) : (
                                <span>{maxPrecipitationProbability}<font style={{ color: 'var(--white)' }}> %</font></span>
                            )}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TonightComponent;

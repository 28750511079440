import { useState, useRef, useEffect } from 'react';
import { useLoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import {
  Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, RadioGroup, Radio, Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownSection,
  DropdownItem,
  Input, Tooltip, Link, Divider, Select, SelectItem, SelectSection
} from "@nextui-org/react";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { app } from '../firebase';

import { Geolocation } from '@capacitor/geolocation';
import { useTranslation } from 'react-i18next';
import { Setting2, ArrowDown2, Danger, Location, Star1, SearchNormal1 } from "iconsax-react";
import { useLocationData } from './GlobalContext';


const API_KEY = 'AIzaSyC7deepZwC-sIZRX8CI--U2QQYcFFJamKM'; // Remplacez YOUR_API_KEY par votre clé API
const libraries = ['places']; // Définissez les bibliothèques en dehors du composant

const SearchBar = ({ onPlaceSelected }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [city, setCity] = useState('');
  const [activeButton, setActiveButton] = useState(() => localStorage.getItem('unit') || 'metric');
  const [favoriteAddress, setFavoriteAddress] = useState('');
  const [favorites, setFavorites] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [useGPS, setUseGPS] = useState(false);
  const [gpsError, setGPSError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const [unit, setUnit] = useState(localStorage.getItem('unit') || 'metric'); // Default to 'metric'
  const [tempUnit, setTempUnit] = useState(unit === 'metric' ? '°' : '°F');
  const [windUnit, setWindUnit] = useState(unit === 'metric' ? 'km/h' : 'mph');
  const [selectedModel, setSelectedModel] = useState(localStorage.getItem('best_match') || ''); // Charger le modèle par défaut
  const { user, subscription, userData, setLocation } = useLocationData();


  const modelsByCountry = {
    "Global Models": [
      "best_match",
      "ecmwf_ifs04",
      "ecmwf_ifs025",
      "ecmwf_aifs025",
      "cma_grapes_global",
      "bom_access_global",
      "gfs_seamless",
      "gfs_global",
      "icon_global",
      "gem_global",
      "meteofrance_seamless",
      "meteofrance_arpege_world",
      "ukmo_global_deterministic_10km",
    ],
    "Canada": [
      "gem_seamless",
      "gem_regional",
      "gem_hrdps_continental",
    ],
    "Denmark": [
      "dmi_seamless",
      "dmi_harmonie_arome_europe",
    ],
    "France": [
      "meteofrance_arpege_europe",
      "meteofrance_arome_france",
      "meteofrance_arome_france_hd",
    ],
    "Germany": [
      "icon_seamless",
      "icon_eu",
      "icon_d2",
    ],
    "Italy": [
      "arpae_cosmo_seamless",
      "arpae_cosmo_2i",
      "arpae_cosmo_5m",
    ],
    "Japan": [
      "jma_seamless",
      "jma_msm",
      "jma_gsm",
    ],
    "Netherlands": [
      "knmi_seamless",
      "knmi_harmonie_arome_europe",
      "knmi_harmonie_arome_netherlands",
    ],
    "Norway": [
      "metno_seamless",
      "metno_nordic",
    ],
    "United Kingdom": [
      "ukmo_seamless",
      "ukmo_uk_deterministic_2km",
    ],
    "United States": [
      "gfs_hrrr",
      "ncep_nbm_conus",
      "gfs_graphcast025",
    ],
  };
  const modelLabels = {
    "best_match": "Best Match",
    "ecmwf_ifs04": "ECMWF IFS 0.4°",
    "ecmwf_ifs025": "ECMWF IFS 0.25°",
    "ecmwf_aifs025": "ECMWF AIFS 0.25°",
    "cma_grapes_global": "CMA GRAPES Global",
    "bom_access_global": "BOM ACCESS Global",
    "gfs_seamless": "GFS Seamless",
    "gfs_global": "GFS Global",
    "icon_global": "ICON Global",
    "gem_global": "GEM Global",
    "meteofrance_seamless": "Météo-France Seamless",
    "meteofrance_arpege_world": "Météo-France ARPEGE World",
    "ukmo_global_deterministic_10km": "UKMO Global Deterministic 10km",
    "meteofrance_arpege_europe": "Météo-France ARPEGE Europe",
    "meteofrance_arome_france": "Météo-France AROME France",
    "meteofrance_arome_france_hd": "Météo-France AROME France HD",
    "ukmo_seamless": "UKMO Seamless",
    "ukmo_uk_deterministic_2km": "UKMO UK Deterministic 2km",
    "icon_seamless": "ICON Seamless",
    "icon_eu": "ICON EU",
    "icon_d2": "ICON D2",
    "gfs_hrrr": "GFS HRRR",
    "ncep_nbm_conus": "NCEP NBM CONUS",
    "gfs_graphcast025": "GFS GraphCast 0.25°",
    "gem_seamless": "GEM Seamless",
    "gem_regional": "GEM Regional",
    "gem_hrdps_continental": "GEM HRDPS Continental",
    "arpae_cosmo_seamless": "ARPAE COSMO Seamless",
    "arpae_cosmo_2i": "ARPAE COSMO 2i",
    "arpae_cosmo_5m": "ARPAE COSMO 5m",
    "jma_seamless": "JMA Seamless",
    "jma_msm": "JMA MSM",
    "jma_gsm": "JMA GSM",
    "metno_seamless": "MetNo Seamless",
    "metno_nordic": "MetNo Nordic",
    "knmi_seamless": "KNMI Seamless",
    "knmi_harmonie_arome_europe": "KNMI Harmonie AROME Europe",
    "knmi_harmonie_arome_netherlands": "KNMI Harmonie AROME Netherlands",
    "dmi_seamless": "DMI Seamless",
    "dmi_harmonie_arome_europe": "DMI Harmonie AROME Europe"
  };
  const modelDetails = {
    best_match: {
      label: "Best Match",
      description: "Provides the best forecast for any given location worldwide.",
    },
    ecmwf_ifs04: {
      label: "ECMWF IFS 0.4°",
      description: "Global weather model from ECMWF with a resolution of 0.4°.",
    },
    ecmwf_ifs025: {
      label: "ECMWF IFS 0.25°",
      description: "High-resolution ECMWF global weather model (0.25°).",
    },
    ecmwf_aifs025: {
      label: "ECMWF AIFS 0.25°",
      description: "Alternative ECMWF model providing detailed global forecasts.",
    },
    cma_grapes_global: {
      label: "CMA GRAPES Global",
      description: "Global model developed by the China Meteorological Administration.",
    },
    bom_access_global: {
      label: "BOM ACCESS Global",
      description: "Australian global weather model from the Bureau of Meteorology.",
    },
    gfs_seamless: {
      label: "GFS Seamless",
      description: "Combines all GFS models from a given provider into a seamless prediction.",
    },
    gfs_global: {
      label: "GFS Global",
      description: "Global Forecast System (GFS) model developed by NOAA.",
    },
    icon_global: {
      label: "ICON Global",
      description: "German global model developed by DWD with advanced physics.",
    },
    gem_global: {
      label: "GEM Global",
      description: "Global weather model from the Canadian Meteorological Centre.",
    },
    meteofrance_seamless: {
      label: "Météo-France Seamless",
      description: "Combines all Météo-France models from a given provider into a seamless prediction.",
    },
    meteofrance_arpege_world: {
      label: "Météo-France ARPEGE World",
      description: "Global model from Météo-France with a variable resolution.",
    },
    ukmo_global_deterministic_10km: {
      label: "UKMO Global Deterministic 10km",
      description: "Global model from the UK Met Office with a 10km resolution.",
    },
    meteofrance_arpege_europe: {
      label: "Météo-France ARPEGE Europe",
      description: "Regional model for Europe from Météo-France with finer resolution.",
    },
    meteofrance_arome_france: {
      label: "Météo-France AROME France",
      description: "High-resolution regional model focused on France.",
    },
    meteofrance_arome_france_hd: {
      label: "Météo-France AROME France HD",
      description: "High-definition version of AROME for detailed forecasts in France.",
    },
    ukmo_seamless: {
      label: "UKMO Seamless",
      description: "Combines all UK Met Office models from a given provider into a seamless prediction.",
    },
    ukmo_uk_deterministic_2km: {
      label: "UKMO UK Deterministic 2km",
      description: "Ultra-high-resolution UK model with a 2km grid.",
    },
    icon_seamless: {
      label: "ICON Seamless",
      description: "Combines all ICON models from a given provider into a seamless prediction.",
    },
    icon_eu: {
      label: "ICON EU",
      description: "ICON model focused on Europe, offering higher resolution.",
    },
    icon_d2: {
      label: "ICON D2",
      description: "Very high-resolution regional model from ICON for Germany.",
    },
    gfs_hrrr: {
      label: "GFS HRRR",
      description: "High-Resolution Rapid Refresh model for the United States.",
    },
    ncep_nbm_conus: {
      label: "NCEP NBM CONUS",
      description: "National Blend of Models for the contiguous United States.",
    },
    gfs_graphcast025: {
      label: "GFS GraphCast 0.25°",
      description: "High-resolution GFS model designed for advanced visualization.",
    },
    gem_seamless: {
      label: "GEM Seamless",
      description: "Combines all GEM models from a given provider into a seamless prediction.",
    },
    gem_regional: {
      label: "GEM Regional",
      description: "Regional model from the Canadian Meteorological Centre.",
    },
    gem_hrdps_continental: {
      label: "GEM HRDPS Continental",
      description: "High-resolution continental model from GEM for detailed forecasts.",
    },
    arpae_cosmo_seamless: {
      label: "ARPAE COSMO Seamless",
      description: "Combines all ARPAE models from a given provider into a seamless prediction.",
    },
    arpae_cosmo_2i: {
      label: "ARPAE COSMO 2i",
      description: "Regional model from ARPAE focused on Italy.",
    },
    arpae_cosmo_5m: {
      label: "ARPAE COSMO 5m",
      description: "Ultra-high-resolution model for specific regions in Italy.",
    },
    jma_seamless: {
      label: "JMA Seamless",
      description: "Combines all JMA models from a given provider into a seamless prediction.",
    },
    jma_msm: {
      label: "JMA MSM",
      description: "Meso-scale model from JMA for detailed regional forecasts.",
    },
    jma_gsm: {
      label: "JMA GSM",
      description: "Global model from the Japan Meteorological Agency.",
    },
    metno_seamless: {
      label: "MetNo Seamless",
      description: "Ccombines all MetNo models from a given provider into a seamless prediction.",
    },
    metno_nordic: {
      label: "MetNo Nordic",
      description: "Regional model from MetNo focused on Nordic countries.",
    },
    knmi_seamless: {
      label: "KNMI Seamless",
      description: "Combines all KNMI models from a given provider into a seamless prediction.",
    },
    knmi_harmonie_arome_europe: {
      label: "KNMI Harmonie AROME Europe",
      description: "High-resolution model for Europe developed by KNMI.",
    },
    knmi_harmonie_arome_netherlands: {
      label: "KNMI Harmonie AROME Netherlands",
      description: "Localized high-resolution model for the Netherlands.",
    },
    dmi_seamless: {
      label: "DMI Seamless",
      description: "Combines all DMI models from a given provider into a seamless prediction.",
    },
    dmi_harmonie_arome_europe: {
      label: "DMI Harmonie AROME Europe",
      description: "High-resolution European model from DMI.",
    },
  };


  const handleModelSelect = (model) => {
    setSelectedModel(model); // Met à jour l'état
    localStorage.setItem('defaultModel', model); // Enregistre dans le localStorage
  };


  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: libraries, // Utilisez les bibliothèques définies en dehors du composant
  });
  const onLoad = (ref) => {
    searchBox.current = ref;
  };
  const handleUseGPS = async () => {
    setIsLoading(true);
    if (typeof Geolocation !== 'undefined') {
      try {
        const position = await Geolocation.getCurrentPosition();
        const { latitude, longitude } = position.coords;

        localStorage.removeItem('latitude');
        localStorage.removeItem('longitude');
        localStorage.removeItem('cityName');
        localStorage.removeItem('astroData');
        localStorage.removeItem('weatherData');
        localStorage.removeItem('openMeteoData');
        localStorage.removeItem('stargazingDescription');
        setLatitude(latitude);
        setLongitude(longitude);


        localStorage.setItem('latitude', latitude.toString());
        localStorage.setItem('longitude', longitude.toString());

        // Obtenir le nom de l'endroit en utilisant les coordonnées de latitude et longitude
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_KEY}`)
          .then(response => response.json())
          .then(data => {
            if (data.results[0]) {
              const cityName = data.results[0].formatted_address;
              setCity(cityName);
              localStorage.setItem('cityName', cityName);

              setLocation({ lat: latitude, lng: longitude, city: cityName });

            }
            //window.location.reload();

            setIsLoading(false);
          })
          .catch(error => {
            console.error("Error:", error);
          });


      } catch (error) {
        setGPSError(error.message);
      }
    } else {
      setGPSError('Geolocation is not supported by this platform.');
    }
  }

  const onPlacesChanged = () => {

    localStorage.removeItem('latitude');
    localStorage.removeItem('longitude');
    localStorage.removeItem('cityName');
    localStorage.removeItem('astroData');
    localStorage.removeItem('weatherData');

    localStorage.removeItem('openMeteoData');
    localStorage.removeItem('stargazingDescription');
    localStorage.removeItem('openMeteoCacheTimestamp');

    const place = searchBox.current.getPlaces()[0];
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    const cityName = place.formatted_address.split(',')[0];
    const lastUpdated = Date.now(); // Retourne le timestamp UNIX en millisecondes

    setLocation({ lat, lng, city: cityName, lastUpdated: lastUpdated });

    setLatitude(lat);
    setLongitude(lng);
    setCity(cityName);

    onPlaceSelected({ lat, lng, cityName });
    //window.location.reload();

    if (inputRef.current) {
      inputRef.current.value = "";
    }

  };

  const onFavoriteSelect = (favorite) => {
    const { latitude, longitude, city } = favorite;
    const lastUpdated = Date.now(); // Retourne le timestamp UNIX en millisecondes

    setLocation({ lat: latitude, lng: longitude, city: city, lastUpdated: lastUpdated });

    localStorage.removeItem('latitude');
    localStorage.removeItem('longitude');
    localStorage.removeItem('cityName');
    localStorage.removeItem('astroData');
    localStorage.removeItem('weatherData');
    localStorage.removeItem('stargazingDescription');
    localStorage.removeItem('openMeteoData');
    localStorage.removeItem('openMeteoCacheTimestamp');

    localStorage.setItem('cityName', city);
    localStorage.setItem('latitude', latitude);
    localStorage.setItem('longitude', longitude);
    //window.location.reload();
  };

  const searchBox = useRef();
  const options = { types: ['(cities)'] };


  useEffect(() => {
    if (user) {
      const firestore = getFirestore(app);
      const favoritesDocRef = doc(firestore, `users/${user.uid}/ouranos/favorites`);

      const unsubscribe = onSnapshot(favoritesDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const favoritesData = docSnap.data();
          const favoritesArray = Object.keys(favoritesData).map(key => favoritesData[key]);
          setFavorites(favoritesArray);
        } else {
          setFavorites([]);
        }
      }, error => {
        console.error("Error getting document:", error);
      });

      return () => unsubscribe(); // Nettoyer l'abonnement
    }
  }, [user]);


  useEffect(() => {
    const storedModel = localStorage.getItem('defaultModel');
    if (storedModel) {
      setSelectedModel(storedModel);
    }
  }, []);


  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };


  if (!isLoaded) {
    <div style={{ width: '100%', height: '40px' }} className='ContainerSkeleton' ></div>
  }
  const saveDataToLocalStorage = (value) => {
    localStorage.setItem('unit', value);
    localStorage.setItem('tempUnit', tempUnit);
    localStorage.setItem('windUnit', windUnit);

    // Optionally, reset other data from localStorage

    localStorage.removeItem('openMeteoData');
    window.location.reload(); // Optionally reload the page
  };

  // Handle Radio selection
  const handleRadioChange = (value) => {
    const isMetric = value === 'metric';
    setUnit(value); // Update the unit state
    setTempUnit(isMetric ? '°' : '°F'); // Update the temperature unit
    setWindUnit(isMetric ? 'km/h' : 'mph'); // Update the wind unit
  };

  // Fonction pour vérifier si un modèle appartient à "Global Models"
  const isGlobalModel = (model) => {
    return modelsByCountry["Global Models"].includes(model);
  };


  return (
    <div className='flex justify-between py-4' >


      <div>

        {isLoaded && (
          <StandaloneSearchBox
            onLoad={onLoad}
            onPlacesChanged={onPlacesChanged}
            options={options}
          >
            <>
              <Input
                startContent={<SearchNormal1 size={20} className='text-gray-400' />}
                endContent={
                  <button
                    isIconOnly
                    variant='light'
                    className={`gps-button ${isLoading ? 'active' : 'text-gray-200'}`}
                    onClick={handleUseGPS}
                    disabled={isLoading}
                  >
                    {isLoading ? <div className="loaderButton"></div> : <Location size={18} variant="Bold" />}
                  </button>}

                ref={inputRef}
                className='md:w-80	'
                size='md'
                radius='sm'
                type="text"
                placeholder={t('searchbar')}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
              />
              {isInputFocused && (
                <div className="absolute z-50" >
                  {favorites.map((favorite, index) => (
                    <a key={index} className="py-3 px-4 favorite-city" onMouseDown={() => onFavoriteSelect(favorite)}>
                      <div>
                        <Star1 size={14} color='fill-amber-400' className='fill-amber-400' />
                      </div>
                      <p>{favorite.city}</p>
                    </a>
                  ))}
                </div>
              )}


            </>
          </StandaloneSearchBox>
        )}


      </div>


      <Button onPress={onOpen} isIconOnly variant='light'>
        <Setting2 variant="Bold" color='white' />
      </Button>


      <Modal
        size="5xl"
        scrollBehavior="inside"
        hideCloseButton
        disableAnimation={true}
        isDismissable={false}
        placement="center"
        className="purple-dark"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>{t('settings.title')}</ModalHeader>
              <ModalBody className="flex flex-col gap-8">
                <div>
                  <RadioGroup
                    label={t('settings.units.label')}
                    value={unit} // Bind the value of RadioGroup to unit state
                    onValueChange={handleRadioChange} // Update the unit state when a radio button is selected
                  >
                    <Radio value="metric">{t('settings.units.metric')}</Radio>
                    <Radio value="imperial">{t('settings.units.imperial')}</Radio>
                  </RadioGroup>
                </div>



                <div className="flex flex-col ">
                  <div className="flex md:flex-row gap-2 flex-col justify-between items-center  md:gap-12 ">
                    <div className='flex flex-col w-full' >
                      <p className="text-md text-default-600 m-0">
                        {t('settings.default_model.label')}
                      </p>
                      <Link
                        size='sm'
                        color="foreground"
                        isExternal
                        showAnchorIcon
                        href="https://open-meteo.com/en/docs/model-updates"
                        underline="always"
                      >
                        {t('settings.default_model.learn_more')}
                      </Link>
                    </div>


                    <Dropdown className="dark">
                      <DropdownTrigger>
                        <Button
                          size="sm"
                          className="w-full md:min-w-4 md:w-fit">
                          <span >
                            {selectedModel
                              ? modelLabels[selectedModel]
                              : t('settings.default_model.best_match')}
                          </span>
                          <div>
                            <ArrowDown2 size={20} />
                          </div>
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        className="overflow-auto max-h-80 max-w-screen"
                        aria-label={t('settings.default_model.dropdown_aria_label')}
                      >
                        {Object.entries(modelsByCountry).map(([country, models]) => (
                          <DropdownSection
                            key={country}
                            title={country}
                            showDivider
                          >
                            {models.map((model) => (
                              <DropdownItem
                                key={model}
                                onPress={() => handleModelSelect(model)}
                                description={modelDetails[model]?.description}
                                className="py-2"
                              >
                                {modelLabels[model]}
                              </DropdownItem>
                            ))}
                          </DropdownSection>
                        ))}
                      </DropdownMenu>
                    </Dropdown>




                  </div>

                  {selectedModel && !isGlobalModel(selectedModel) && (
                    <div className="flex gap-2 items-center mt-2">
                      <div>
                        <Danger size={18} className="text-red-200" />
                      </div>
                      <p className="m-0 text-red-200 text-sm">
                        <font className="uppercase">
                          {selectedModel}
                        </font>{' '}
                        {t('settings.default_model.warning')}
                      </p>
                    </div>
                  )}
                </div>



                <div className="flex md:flex-row gap-2 flex-col justify-between items-center  md:gap-12">
                  <p className="text-sm ">
                    {t('settings.discord.label')}
                  </p>
                  <Button
                    size="sm"
                    className="w-full md:min-w-4 md:w-fit"
                    isExternal
                    showAnchorIcon
                    as={Link}
                    href="https://discord.gg/bhbUdkjNgu"
                  >
                    {t('settings.discord.button')}
                  </Button>
                </div>



                <div className="flex md:flex-row gap-2 flex-col justify-between items-center  md:gap-12">
                  <p className="text-sm ">
                    {t('settings.shop.label')}
                  </p>
                  <Button
                    size="sm"
                    className="w-full md:min-w-4 md:w-fit"
                    isExternal
                    showAnchorIcon
                    as={Link}
                    href="https://ouranos-shop.myspreadshop.net/"
                  >
                    {t('settings.shop.button')}
                  </Button>
                </div>



              </ModalBody>
              <ModalFooter>
                <Button onPress={onClose}>{t('settings.footer.close')}</Button>
                <Button
                  color="primary"
                  onPress={() => saveDataToLocalStorage(unit)}
                >
                  {t('settings.footer.save')}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

    </div>
  );
};
export default SearchBar;

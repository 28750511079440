import React, { useState, useEffect } from "react";
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, signInWithRedirect, getRedirectResult, signInWithCredential } from "firebase/auth";
import { auth, database } from '../firebase';
import { doc, setDoc, getFirestore } from 'firebase/firestore'; // Importer les fonctions Firestore
import Logo from '../assets/logo.png'
import { useTranslation } from 'react-i18next';
import { Button, Input, Alert, useDisclosure, Spacer, Link } from "@nextui-org/react";
import { getFirebaseErrorMessage } from "./firebaseErrors"; // Ton fichier de gestion des erreurs
import { useNavigate } from 'react-router-dom'; // Import du hook navigate
import { FcGoogle } from "react-icons/fc";
import { Capacitor } from "@capacitor/core"; // Importez Capacitor pour la détection de la plateforme

import { FirebaseAuthentication } from '@capacitor-firebase/authentication';


const SignUp = ({ onBackToLogin }) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [username, setUsername] = useState("");
    const [alert, setAlert] = useState({ visible: false, message: "", color: "default" });
    const [loading, setLoading] = useState(false); // Déclarer l'état loading
    const navigate = useNavigate(); // Initialisation du hook navigate
    const { isOpen, onOpen, onOpenChange } = useDisclosure(); // Gestion de la modal pour l'erreur
    const [error, setError] = useState(null);

    const showAlert = (message, color = "danger") => {
        setAlert({ visible: true, message, color });
        setTimeout(() => setAlert({ visible: false, message: "", color: "default" }), 5000);
    };

    const handleInputChange = (setter) => (e) => {
        const value = e.target.value.replace(/\s+/g, ""); // Supprimer les espaces
        setter(value);
    };


    const handleGoogleLogin = async () => {
        try {
            setLoading(true)
            console.log('Initiating Google Sign-In...');

            // Step 1: Initiate Google Sign-In using Capacitor
            const result = await FirebaseAuthentication.signInWithGoogle();
            console.log('Google Sign-In result:', JSON.stringify(result, null, 2));

            // Step 2: Ensure we have a valid ID token for Firebase Auth
            if (result.credential?.idToken) {
                const credential = GoogleAuthProvider.credential(result.credential.idToken);

                // Step 3: Sign in to Firebase Auth with the credential
                const userCredential = await signInWithCredential(auth, credential);
                console.log('Firebase user authenticated:', JSON.stringify(userCredential.user, null, 2));

                // Step 4: Update user data in Firestore
                const { email, uid } = userCredential.user;
                console.log('Updating Firestore with user data...');
                await setDoc(
                    doc(getFirestore(), "users", uid),
                    {
                        email,
                        createdAt: new Date(),
                        lastLogin: new Date(), // Optional: track the last login time
                    },
                    { merge: true } // Avoid overwriting existing data
                );
                console.log('User data successfully updated in Firestore.');

                // Step 5: Navigate to the main app page
                console.log('Navigating to /myprofile...');
                navigate('/myprofile');
                setLoading(false)
            } else {
                console.warn('Google Sign-In did not return a valid credential.');
            }
        } catch (error) {
            console.error('Google Sign-In Error:', error);
            setError(error.message); // Show error if needed
            onOpen(); // Optional: open a modal to display the error
        }
    };


    const signUp = async (e) => {
        e.preventDefault();


        if (!email || !password || !confirmPassword || !username) {
            showAlert(t('pleaseFillAllFields'), "danger");
            return;
        }

        if (password.length < 6) {
            showAlert(t('passwordTooShort'), "danger");
            return;
        }

        if (password !== confirmPassword) {
            showAlert(t('passwordsDoNotMatch'), "danger");
            return;
        }

        setLoading(true);

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Sauvegarder les informations utilisateur dans Firestore
            try {
                await setDoc(doc(getFirestore(), "users", user.uid), {
                    email: email,
                    username: username,
                });
                showAlert(t('accountCreatedSuccess'), "success"); // Alerte de succès

                onBackToLogin();
            } catch (firestoreError) {
                console.error("Error saving user information to Firestore", firestoreError);
                showAlert(t('errorSavingUserInfo'), "danger");
            }
        } catch (firebaseError) {
            const errorMessage = getFirebaseErrorMessage(firebaseError);
            showAlert(errorMessage, "danger");
        } finally {
            setLoading(false); // Arrêter le chargement après la fin du processus
        }
    };

    return (
        <div className="flex  justify-center h-screen w-full p-4 pt-4 md:pt-16">
            <div className="w-full max-w-md">
                <form className="flex flex-col gap-4 w-full" onSubmit={signUp}>
                    <img className="w-12 md:w-16 mx-auto" src={Logo} alt={t('logoAltText')} />
                    <h1 className="text-center">{t('createAccount')}</h1>


                    <Button
                        size='lg'
                        variant="bordered"
                        onPress={handleGoogleLogin}
                        className="w-full"
                        isLoading={loading}
                    >
                        <FcGoogle size={24} />
                        Continue with Google
                    </Button>

                    <p className="text-center text-zinc-400">Or</p>



                    <Input
                        variant='faded'
                        size='lg'
                        label={t('usernameLabel')}
                        autoComplete="username"
                        type="text"
                        value={username}
                        placeholder={t('usernamePlaceholder')}
                        onChange={handleInputChange(setUsername)}
                    />

                    <Input
                        variant='faded'
                        size='lg'
                        label={t('emailLabel')}
                        autoComplete="email"
                        type="email"
                        placeholder={t('emailPlaceholder')}
                        value={email}
                        onChange={handleInputChange(setEmail)}
                    />

                    <Input
                        variant='faded'
                        size='lg'
                        label={t('passwordLabel')}
                        autoComplete="new-password"
                        type="password"
                        placeholder={t('passwordPlaceholder')}
                        value={password}
                        onChange={handleInputChange(setPassword)}
                    />

                    <Input
                        variant='faded'
                        size='lg'
                        label={t('confirmPasswordLabel')}
                        autoComplete="new-password"
                        type="password"
                        placeholder={t('confirmPasswordPlaceholder')}
                        value={confirmPassword}
                        onChange={handleInputChange(setConfirmPassword)}
                    />

                    {alert.visible && (
                        <Alert
                            variant="flat"
                            color={alert.color}
                            title={alert.message}
                        />
                    )}

                    <div>
                        <Button
                            size='lg'
                            disabled={loading}
                            isLoading={loading}
                            className="w-full"
                            color="primary"
                            type="submit"
                        >
                            {t('registerButton')}
                        </Button>

                        <p className="mt-4 text-center w-full text-zinc-400">
                            {"Already have an account? "}
                            <Link
                                color="foreground"
                                className="cursor-pointer"
                                onPress={onBackToLogin}
                            >
                                Login
                            </Link>
                        </p>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default SignUp;


import 'resize-observer-polyfill/dist/ResizeObserver.global';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './components/i18n';
import { AptabaseProvider } from '@aptabase/react';
import { NextUIProvider } from "@nextui-org/react";
import { LocationProvider } from './components/GlobalContext.js';
import { GoogleOAuthProvider } from '@react-oauth/google'; // Import the provider
const root = document.getElementById('root');

if (!root) {
  throw new Error("No root element found");
}

ReactDOM.createRoot(root).render(
  <GoogleOAuthProvider clientId="225215269446-hhfe7rsbm2lvgaidbfek2uttr59m3f4p.apps.googleusercontent.com">
    <React.StrictMode>
      <AptabaseProvider appKey="A-EU-3189783161">
        <Router>
          <LocationProvider>
            <NextUIProvider>
              <div className=' text-foreground purple-dark' >


                <App />
              </div>
            </NextUIProvider>
          </LocationProvider>
        </Router>
      </AptabaseProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

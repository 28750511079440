import React, { useState } from 'react';
import {
    ResponsiveContainer,
    AreaChart,
    CartesianGrid,
    Tooltip,
    Area,
    XAxis,
    YAxis,
    ReferenceLine,
    ReferenceArea,
} from "recharts";
import CustomTooltip from "./CustomTooltip";
import { useTranslation } from 'react-i18next';
import { Diagram, InfoCircle, Maximize4 } from "iconsax-react";

import { FiSunset, FiSunrise } from "react-icons/fi";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, ModalContent } from "@nextui-org/react";
import { Popover, PopoverTrigger, PopoverContent } from "@nextui-org/react";


const CustomAreaChart = ({
    data,
    sunset,
    sunrise,
    gradientId = "dynamicGradient",
    yDomain = [0, 5],
    yTicks = [0, 1, 2, 3, 4, 5],
}) => {
    const nextDaySunrise = new Date(sunrise);
    nextDaySunrise.setDate(nextDaySunrise.getDate() + 1);


    const sunsetHour = sunset ? new Date(sunset).getHours() : null;
    const nextDaySunriseHour = sunrise ? nextDaySunrise.getHours() : null;
    const { t } = useTranslation();

    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    const interpolateMinutes = (data) => {
        const interpolatedData = [];
        for (let i = 0; i < data.length - 1; i++) {
            const start = data[i];
            const end = data[i + 1];

            const startMinute = parseInt(start.hour, 10) * 60;
            const endMinute = parseInt(end.hour, 10) * 60;

            // Interpolate every 10 minutes
            for (let minute = startMinute; minute < endMinute; minute += 15) {
                const ratio = (minute - startMinute) / (endMinute - startMinute);
                const interpolatedRating = start.rating + (end.rating - start.rating) * ratio;

                interpolatedData.push({
                    hour: minute / 60, // Keep the hour in decimal format for XAxis
                    rating: interpolatedRating,
                    clouds: start.clouds + (end.clouds - start.clouds) * ratio,
                    seeing: start.seeing + (end.seeing - start.seeing) * ratio,
                    windSpeed: start.windSpeed + (end.windSpeed - start.windSpeed) * ratio,
                    humidity: start.humidity + (end.humidity - start.humidity) * ratio,
                    temperature: start.temperature + (end.temperature - start.temperature) * ratio,
                    dew_point_2m: start.dew_point_2m + (end.dew_point_2m - start.dew_point_2m) * ratio
                });
            }
        }

        // Add the last point
        interpolatedData.push({
            hour: parseInt(data[data.length - 1].hour, 10),
            rating: data[data.length - 1].rating,
            clouds: data[data.length - 1].clouds,
            seeing: data[data.length - 1].seeing,
            windSpeed: data[data.length - 1].windSpeed,
            humidity: data[data.length - 1].humidity,
            temperature: data[data.length - 1].temperature,
            dew_point_2m: data[data.length - 1].dew_point_2m
        });

        return interpolatedData;
    };


    const roundToQuarterHour = (date) => {
        const minutes = date.getMinutes();
        const roundedMinutes = Math.round(minutes / 15) * 15;
        date.setMinutes(roundedMinutes);
        return date;
    };
    const roundedSunrise = sunrise ? roundToQuarterHour(new Date(sunrise)) : null;
    const roundedSunset = sunset ? roundToQuarterHour(new Date(sunset)) : null;


    const interpolatedData = (interpolateMinutes(data));


    const renderChart = (height) => (
        <ResponsiveContainer width="100%" height={height}>
            <AreaChart data={interpolatedData}>
                <defs>
                    <linearGradient id={gradientId} gradientTransform="rotate(90)">
                        <stop offset="0%" stopColor="rgb(94 234 212)" stopOpacity={1} />
                        <stop offset="50%" stopColor="rgb(107 114 128)" stopOpacity={0.6} />
                        <stop offset="100%" stopColor="rgb(75 85 99)" stopOpacity={0.4} />
                    </linearGradient>
                </defs>

                {/* Zones de Référence */}
                {sunsetHour !== null && nextDaySunriseHour !== null && (
                    <ReferenceArea x1={sunsetHour + 2} x2={nextDaySunriseHour - 2} opacity={1} fill="black" />
                )}

                {roundedSunset && (
                    <ReferenceLine
                        x={roundedSunset.getHours() + roundedSunset.getMinutes() / 60}
                        opacity={0.2}
                        label={({ viewBox }) => (
                            <foreignObject x={viewBox.x - 8} y={viewBox.y + height - 32} width="22" height="18">
                                <FiSunset size={16} className="text-amber-200" />
                            </foreignObject>
                        )}
                    />
                )}
                {roundedSunrise && (
                    <ReferenceLine
                        x={roundedSunrise.getHours() + roundedSunrise.getMinutes() / 60}
                        opacity={0.2}
                        label={({ viewBox }) => (
                            <foreignObject x={viewBox.x - 8} y={viewBox.y + height - 32} width="22" height="18">
                                <FiSunrise size={16} className="text-amber-200" />
                            </foreignObject>
                        )}
                    />
                )}


                {/* Grille et Axes */}
                <CartesianGrid stroke="var(--grey20)" />
                <XAxis
                    dataKey="hour"
                    stroke="var(--grey20)"
                    fontSize={14}
                    tick={{ fill: "var(--grey10)" }}
                    interval={10} // Afficher une étiquette toutes les heures
                    tickFormatter={(hour) => {
                        const hours = Math.floor(hour);
                        return `${hours}h`;
                    }}
                    textAnchor='start'
                />



                <YAxis
                    domain={yDomain}
                    ticks={yTicks}
                    orientation="right"
                    stroke="var(--grey20)"
                    width={20}
                    tick={{ fill: "var(--grey10)" }}
                />

                {/* Contenu Principal */}
                <Tooltip
                    content={<CustomTooltip isLight={!isOpen} />}
                    cursor={{ stroke: "var(--white)", strokeWidth: 2 }}
                />

                <Area
                    type="basis"
                    dataKey="rating"
                    stroke={`url(#${gradientId})`}
                    fill={`url(#${gradientId})`}

                    strokeWidth={5}
                    activeDot={{
                        stroke: "rgb(9, 12, 18)",
                        fill: "var(--white)",
                        strokeWidth: 3,
                        r: 8,
                    }}
                />
            </AreaChart>
        </ResponsiveContainer>
    );

    return (
        <div className='w-full bg-gray-900 rounded-md h-fit p-3 m-0 md:sticky top-0 cursor-pointer' >
            {/* Graphique visible par défaut */}
            <div className="w-full sticky top-0 cursor-pointer ">

                <div className='flex align-center justify-between pb-3' >
                    <Popover backdrop="opaque" >
                        <PopoverTrigger>
                            <div className='cursor-pointer flex gap-1 ' >
                                <div className='flex gap-1 items-center' >
                                    <Diagram size={16} className="text-gray-500" />
                                    <h2 >{t("graph")}</h2>
                                </div>

                                <InfoCircle className='text-gray-500' size={18} />
                            </div>
                        </PopoverTrigger>
                        <PopoverContent className="max-w-64 purple-dark  p-4">
                            {t('infograph')}
                        </PopoverContent>

                    </Popover>

                    <button onClick={onOpen} >
                        <Maximize4 className='text-gray-300' size={20} />
                    </button>

                </div>

                <div onClick={onOpen} >
                    {renderChart(200)}
                </div>

            </div>

            {/* Modale avec le graphique en grand */}
            <Modal placement='center' size='5xl' className='purple-dark' isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    <ModalHeader>
                        <div className='flex gap-1 items-center' >
                            <Diagram size={16} className="text-gray-500" />
                            <h2 >{t("graph")}</h2>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="h-96">{renderChart(400)}</div>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default CustomAreaChart;
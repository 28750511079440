import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Button, Link } from "@nextui-org/react";
import { useLocationData } from './GlobalContext';
import { Map1, Lock, Add, InfoCircle } from "iconsax-react";
import PremiumNotification from './PremiumNotification';

const LightPollutionMap = ({ user }) => {
    const mapContainerRef = useRef(null);
    const [opacity, setOpacity] = useState(0.5);
    const [showLegend, setShowLegend] = useState(false);
    const [showSlider, setShowSlider] = useState(false);
    const [mapPosition, setMapPosition] = useState('relative');
    const { location, setLocation } = useLocationData();
    const [isMapReady, setIsMapReady] = useState(false); // Indique si la carte peut être affichée
    const { t } = useTranslation();
    const [isFullScreen, setIsFullScreen] = useState(false);
    const mapInstanceRef = useRef(null);
    const navigate = useNavigate(); // Hook pour la navigation
    const { subscription, setSubscription } = useLocationData();

    const handleClick = () => {
        navigate('/dailyforecast'); // Redirige vers la page /daily
    };



    // Charger la position depuis le localStorage au montage du composant
    useEffect(() => {
        const savedLat = localStorage.getItem('latitude');
        const savedLng = localStorage.getItem('longitude');

        if (savedLat && savedLng) {
            const lat = parseFloat(savedLat);
            const lng = parseFloat(savedLng);

            if (!isNaN(lat) && !isNaN(lng)) {
                setLocation((prevLocation) => ({
                    ...prevLocation,
                    lat,
                    lng
                }));
                setIsMapReady(true); // Les données sont valides, on peut afficher la carte
                return;
            }
        }

        console.warn("Coordonnées non trouvées ou invalides dans le localStorage.");
        setIsMapReady(false); // Les données sont absentes ou invalides
    }, [setLocation]);


    const enableFullScreen = () => {
        if (mapContainerRef.current) {
            setIsFullScreen(true);
            mapContainerRef.current.style.position = "fixed";
            mapContainerRef.current.style.top = "0";
            mapContainerRef.current.style.left = "0";
            mapContainerRef.current.style.width = "100vw";
            mapContainerRef.current.style.height = "100vh";
            mapContainerRef.current.style.zIndex = "9999";

            if (mapInstanceRef.current) {
                mapInstanceRef.current.invalidateSize(); // Ajuste la taille de la carte pour s'adapter au plein écran
            }

        }

    };

    const disableFullScreen = () => {
        if (mapContainerRef.current) {
            setIsFullScreen(false)

            mapContainerRef.current.style.position = "relative";
            mapContainerRef.current.style.top = "";
            mapContainerRef.current.style.left = "";
            mapContainerRef.current.style.width = "100%";
            mapContainerRef.current.style.height = "7rem"; // Taille initiale
            mapContainerRef.current.style.zIndex = "0";
        }
    };



    const toggleLegend = () => setShowLegend(!showLegend);

    const LightPollutionLegend = () => {
        const levels = [
            { color: '#FFFFFF', range: '<17.5', description: 'Trop lumineux' },
            { color: '#808080', range: '<17.8', description: 'Extrêmement lumineux' },
            { color: '#FF0000', range: '20.5', description: 'Très lumineux' },
            { color: '#FFA500', range: '20.8', description: 'Lumineux' },
            { color: '#FFFF00', range: '21.4', description: 'Modérément sombre' },
            { color: '#008000', range: '21.8', description: 'Sombre' },
            { color: '#000080', range: '21.9', description: 'Très sombre' },
        ];

        return (
            <div style={{ padding: '10px', backgroundColor: 'rgba(0, 0, 0, 0.2)', borderRadius: '5px', maxWidth: '32em' }}>
                <div style={{
                    height: '20px',
                    borderRadius: '5px',
                    backgroundImage: 'linear-gradient(to left, #000080, #008000, #FFFF00, #FFA500, #FF0000,  #FFFFFF)',
                }} />
                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '12px', marginTop: '6px', color: 'white' }}>
                    <span>Clearer</span>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        {levels.map((level, index) => (
                            <span key={index} style={{ flex: 1, textAlign: 'center' }}>{level.range}</span>
                        ))}
                    </div>
                    <span>Darker</span>
                </div>
                <p style={{ marginTop: '10px', textAlign: 'left', fontSize: '14px' }}>
                    Zenith sky brightness in Magnitudes/arc square second. The higher the value, the darker the sky.
                    NASA VIIRS 2022
                </p>
            </div>
        );
    };

    useEffect(() => {
        if (!isMapReady || !location.lat || !location.lng) return;


        const isValidCoordinates = location.lat && location.lng && !isNaN(location.lat) && !isNaN(location.lng);

        if (!isValidCoordinates) {
            console.error('Invalid coordinates:', location);
            return;
        }

        const center = isValidCoordinates ? [location.lat, location.lng] : [0, 0];
        const zoom = subscription === 1 ? 14 : 3;

        const svgIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"><path d="M20.621 8.45c-1.05-4.62-5.08-6.7-8.62-6.7h-.01c-3.53 0-7.57 2.07-8.62 6.69-1.17 5.16 1.99 9.53 4.85 12.28a5.436 5.436 0 0 0 3.78 1.53c1.36 0 2.72-.51 3.77-1.53 2.86-2.75 6.02-7.11 4.85-12.27Zm-8.62 5.01a3.15 3.15 0 1 1 0-6.3 3.15 3.15 0 0 1 0 6.3Z" fill="#1f2937"></path></svg>`;
        const encodedSvgIcon = encodeURIComponent(svgIcon).replace(/'/g, "%27").replace(/"/g, "%22");

        const customIcon = L.icon({
            iconUrl: `data:image/svg+xml,${encodedSvgIcon}`,
            iconSize: [26, 26],

            popupAnchor: [0, -48],
        });

        let map = L.map(mapContainerRef.current, {
            center,
            zoom,
            dragging: subscription === 1, // Autoriser le drag uniquement si `subscription === 1`
            zoomControl: subscription === 1,
        });

        mapInstanceRef.current = map; // Sauvegarde l'instance dans la référence


        L.tileLayer('https://api.mapbox.com/styles/v1/pleiode/clu5g9e73008z01piav6q2hqd/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicGxlaW9kZSIsImEiOiJjangwY3pleG8xeGNyNDFtbzhqZ2l6NWdiIn0.trjD5sj0sj09hF3RKxHiOA', {
            attribution: '&copy; OpenStreetMap contributors',
            zIndex: '0'
        }).addTo(map);

        const lightPollutionLayer = L.tileLayer('https://djlorenz.github.io/astronomy/lp2022/overlay/tiles/tile_{z}_{x}_{y}.png', {
            minZoom: 2,
            maxNativeZoom: 8,
            maxZoom: 19,
            tileSize: 1024,
            zoomOffset: -2,
            opacity: opacity,
            className: 'light-pollution-tiles'
        }).addTo(map);

        if (subscription === 0) {
            // Désactiver toutes les interactions
            map.dragging.disable();
            map.scrollWheelZoom.disable();
            map.doubleClickZoom.disable();
            map.touchZoom.disable();
            map.keyboard.disable();
            map.boxZoom.disable();
        }


        lightPollutionLayer.on('tileload', function (e) {
            e.tile.style.filter = 'blur(2px)';
        });

        L.marker([location.lat, location.lng], { icon: customIcon }).addTo(map);

        return () => {
            map.remove();
        };
    }, [isMapReady, location.lat, location.lng, opacity, subscription]);

    useEffect(() => {
        const updateMapPosition = () => {
            if (window.innerWidth < 768) {
                setMapPosition('absolute');
            } else {
                setMapPosition('relative');
            }
        };

        updateMapPosition();
        window.addEventListener('resize', updateMapPosition);

        return () => window.removeEventListener('resize', updateMapPosition);
    }, []);

    return (
        <div >
            {isMapReady ? (
                subscription === 1 ? (
                    <>
                        <div
                            onClick={enableFullScreen}
                            ref={mapContainerRef}
                            id="myMap"
                            className="rounded-md w-full md:h-full h-28  z-0"
                        >


                            {isFullScreen && (
                                <button
                                    variant='light'
                                    isIconOnly
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        disableFullScreen(); // Quitter le plein écran
                                    }}
                                    style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "10px",
                                        zIndex: 1001,
                                    }}
                                >
                                    <Add size={30} color='white' className='rotate-45' />
                                </button>
                            )}


                            <div style={{ zIndex: 1000 }} className="absolute top-2 left-2 flex " >
                                <div className="flex items-center gap-1 opacity-60">
                                    <Map1 className="text-white" size={16} />
                                    <h2 className="text-white">{t('lightpollution')}</h2>
                                </div>
                            </div>

                            {isFullScreen && (
                                <Button
                                    onPress={() => {
                     
                                        toggleLegend();
                                    }}
                                    isIconOnly
                                    style={{
                                        position: "absolute",
                                        bottom: "120px",
                                        right: "10px",
                                        zIndex: 1000,
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <InfoCircle />
                                </Button>
                            )}

                            {showLegend && (
                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: "170px",
                                        right: "10px",
                                        zIndex: 1000,
                                        background: "#192131",
                                        borderRadius: "6px",
                                    }}
                                >
                                    <LightPollutionLegend />
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <>

                        <div ref={mapContainerRef} className="w-full md:h-full h-28 rounded-md z-0" >

                            <div style={{ zIndex: 1000 }} className=" absolute top-2 left-2 flex " >
                                <div className=" flex items-center gap-1 opacity-60">
                                    <Map1 className="text-white" variant="Bold" size={16} />
                                    <h2 className=" text-white">{t('lightpollution')}</h2>
                                </div>
                            </div>

                            <div style={{ zIndex: 1000 }} className="absolute inset-0 flex justify-center items-center ">
                                <PremiumNotification />
                            </div>
                        </div>
                    </>
                )
            ) : (
                <div className="mt-2 w-full h-28 flex items-center justify-center text-gray-500">
                    <p>Aucune donnée disponible pour afficher la carte.</p>
                </div>
            )}
        </div >
    );

};

export default LightPollutionMap;

import React from 'react';
import PlanetComponent from './PlanetComponent'
import { useTranslation } from 'react-i18next';


const PlanetTab = () => {
    const { t } = useTranslation(); // Traduction


    return (
        <div className='pt-8' >
            <h1>{t('Planets')}</h1>
            <PlanetComponent />
        </div>
    );
};

export default PlanetTab;
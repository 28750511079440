import React, { useEffect, useState } from 'react';
import { NotionRenderer } from 'react-notion-x';

const NotionPage = () => {
    const [pageData, setPageData] = useState(null);

    useEffect(() => {
        // Remplace l'URL par l'URL publique de la page Notion
        const notionPageUrl = 'http://ouranos-api.notion.site';

        fetch(`https://api.notion.so/v1/pages/${notionPageUrl}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${process.env.NOTION_API_KEY}`,
                'Notion-Version': '2021-05-13', // Ou la version Notion API que tu utilises
            },
        })
            .then((response) => response.json())
            .then((data) => setPageData(data))
            .catch((error) => console.error('Erreur de récupération de la page Notion:', error));
    }, []);

    if (!pageData) {
        return <div>Chargement...</div>;
    }

    return (
        <div className="notion-page">
            <NotionRenderer blockMap={pageData} />
        </div>
    );
};

export default NotionPage;

import React, { useEffect, useState } from 'react';
import { Navigate, Route, Link, Routes, useLocation } from 'react-router-dom';
import { InAppReview } from '@capacitor-community/in-app-review';
import { Capacitor } from '@capacitor/core';
import { useAptabase } from '@aptabase/react';
import AlertSystem from './components/AlertSystem.js'
import Logo from './assets/logo-header.svg'
import './App.css';
import { useTranslation } from 'react-i18next';
import MetaTags from './components/MetaTags.js';
import Tab1 from './components/Tab1';
import Tab3 from './components/Tab3';
import Tab4 from './components/Tab4';
import About from './components/About';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import SearchBar from './components/SearchBar';
import Footer from './components/footer';
import Subscriptionsucess from './components/TransitionPageStripe';
import VersionCheck from './components/VersionCheck.js';
import PullToRefresh from "react-pull-to-refresh";
import { Calendar1, Calendar, ProfileCircle, Book1, UserOctagon, HierarchySquare2 } from "iconsax-react";
import PlanetTab from './components/PlanetTab.js';
import ApiInfo from './components/ApiInfo.js'
import Api_doc from './components/Api_doc.js'
import { useDisclosure, Button } from "@nextui-org/react";
import News from './components/News.js'
import ArticleDetail from './components/ArticleDetail'; // Votre page d'article en détail
import Admin from './components/Admin'; // Votre page d'article en détail
import { useLocationData } from './components/GlobalContext.js';
import { IoPlanetOutline } from "react-icons/io5";
import { IoPlanet } from "react-icons/io5";
import Embed from './components/embed.js';
import { useNavigate } from 'react-router-dom'; // Import du hook navigate
import { FiExternalLink } from "react-icons/fi";



function App() {
  {/* 
  useEffect(() => {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        PushNotifications.register();
      }
    });

    PushNotifications.addListener('registration',
      (token) => {
        console.log('Token de notification push:', token.value);
        // Enregistrer le token sur votre serveur ou dans Firebase
      }
    );
  }, []);
*/}
  const { isOpen, onOpen, onOpenChange } = useDisclosure(); // Gestion de la modal pour l'erreur
  const { t } = useTranslation();
  const [latitude, setLatitude] = useState(parseFloat(localStorage.getItem('latitude')));
  const [longitude, setLongitude] = useState(parseFloat(localStorage.getItem('longitude')));
  const [city, setCity] = useState(localStorage.getItem('cityName'));
  const { trackEvent } = useAptabase();
  const [isNative, setIsNative] = useState(false); // Ajout de l'état pour détecter si l'app est native
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { user, subscription, userData } = useLocationData();
  const navigate = useNavigate(); // Initialisation du hook navigate
  const [error, setError] = useState(null);

  const location = useLocation();
  const handlePlaceSelected = ({ lat, lng, cityName }) => {
    localStorage.setItem('latitude', lat);
    localStorage.setItem('longitude', lng);
    localStorage.setItem('cityName', cityName);
    setLatitude(lat);
    setLongitude(lng);
    setCity(cityName);
  };



  useEffect(() => {
    // Suivre les changements de chemin
    const currentPath = location.pathname;
    trackEvent('location_change', { pathname: currentPath });
    console.log(`Pathname changed: ${currentPath}`);
  }, [location.pathname, trackEvent]);



  useEffect(() => {
    // Détecte si l'application fonctionne sur une plateforme native
    setIsNative(Capacitor.isNativePlatform());
  }, []);




  useEffect(() => {
    const firstLaunchDate = localStorage.getItem('firstLaunchDate');
    const now = Date.now();

    if (!firstLaunchDate) {
      // Premier lancement, stockez la date actuelle
      localStorage.setItem('firstLaunchDate', now.toString());
    } else {
      // Vérifiez si 3 semaines se sont écoulées depuis le premier lancement
      const threeWeeksInMs = 604800000; // 1 semaine en millisecondes
      const timeSinceFirstLaunch = now - parseInt(firstLaunchDate);

      if (timeSinceFirstLaunch >= threeWeeksInMs) {
        // 3 semaines ont passé, demandez une notation
        // Assurez-vous que cette partie ne s'exécute qu'une seule fois
        const hasRequestedReview = localStorage.getItem('hasRequestedReview');
        if (!hasRequestedReview) {
          InAppReview.requestReview();
          console.log('rate'); // Log pour démonstration
          localStorage.setItem('hasRequestedReview', 'true');
        }
      }
    }
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);




  //// END Subscription Manager /////

  const navItems = [
    {
      path: "/tonightforecast",
      label: t('tonight'),
      icon: (
        <Calendar1
          variant={location.pathname === "/tonightforecast" ? 'Bold' : 'Regular'}
          fill={location.pathname === "/tonightforecast" ? 'fill-white' : 'fill-gray-400'}
        />
      ),
    },
    {
      path: "/dailyforecast",
      label: t('daily'),
      icon: (
        <Calendar
          variant={location.pathname === "/dailyforecast" ? 'Bold' : 'Regular'}
          fill={location.pathname === "/dailyforecast" ? 'fill-white' : 'fill-gray-400'}
        />
      ),
    },
    {
      path: "/planet",
      label: t('Planets'),
      icon: location.pathname === "/planet" ? (
        <IoPlanet size={24} />
      ) : (
        <IoPlanetOutline size={24} />
      ),
    },
    {
      path: "/news",
      label: t('news'),
      icon: (
        <Book1
          variant={location.pathname === "/news" ? 'Bold' : 'Regular'}
          fill={location.pathname === "/news" ? 'fill-white' : 'fill-gray-400'}
        />
      ),
    },
    // Conditionnel pour l'élément API

    {
      path: "/myprofile",
      label: t('profile'),
      icon: (
        <ProfileCircle
          variant={location.pathname === "/myprofile" ? 'Bold' : 'Regular'}
          fill={location.pathname === "/myprofile" ? 'fill-white' : 'fill-gray-400'}
        />
      ),
    },

    ...(!isNative
      ? [
        {
          path: "/api",
          label: t('API'),
          icon: (
            <HierarchySquare2
              variant={location.pathname === "/api" ? 'Bold' : 'Regular'}
              fill={location.pathname === "/api" ? 'fill-white' : 'fill-gray-400'}
            />
          ),
          target: "_blank", // Ajout de l'option pour ouvrir dans un nouvel onglet
          externalIcon: (
            <FiExternalLink /> // Ajout de l'icône d'external link
          ),
        },
      ]
      : []),


    // Conditionnel pour l'utilisateur admin
    ...(userData && userData.role === 'admin'
      ? [
        {
          path: "/admin",
          label: 'Admin',
          icon: (
            <UserOctagon
              variant={location.pathname === "/admin" ? 'Bold' : 'Regular'}
              fill={location.pathname === "/admin" ? 'fill-white' : 'fill-gray-400'}
            />

          ),
        },
      ]
      : []),
  ];

  const NavItem = ({ path, label, icon, target, externalIcon }) => (
    <li>
      {target ? (
        <a
          href={path}
          target={target}
          rel={target === "_blank" ? "noopener noreferrer" : undefined}
          className={`md:px-4 md:pr-16 md:py-2 nav-link flex items-center ${location.pathname === path
              ? "lg:bg-slate-800 text-white rounded-lg"
              : "text-gray-400"
            }`}
        >
          {icon}
          <span className="flex gap-1 items-center">
            {label}
            {externalIcon && externalIcon} {/* Affiche l'icône external à côté */}
          </span>
        </a>
      ) : (
        <Link
          to={path}
          className={`md:px-4 md:pr-16 md:py-2 nav-link flex items-center ${location.pathname === path
              ? "lg:bg-slate-800 text-white rounded-lg"
              : "text-gray-400"
            }`}
        >
          {icon}
          <span className="flex gap-1 items-center">
            {label}
            {externalIcon && externalIcon}
          </span>
        </Link>
      )}
    </li>
  );




  return (
    <div className="App bg-black">


      {/* 
      <div className='hidden' >
        <GoogleLogin
          onSuccess={handleGoogleLogin}
          onError={() => setError("Google Login failed")}
          useOneTap
        />
      </div>*/}


      <MetaTags />
      <AlertSystem />
      <VersionCheck />

      {/* Affichage exclusif du composant Embed pour la route "/embed" */}
      {location.pathname === "/api" ? (
        <ApiInfo />
      ) : (



        <>
          {/* NavBar */}
          <nav className="w-full lg:w-fit bg-gray-900 navbar z-40">
            <div className="Logo-header flex justify-center items-center py-4 border-b border-gray-800 ">
              <img src={Logo} alt="Logo Ouranos" />
            </div>
            <ul className='md:p-3 pt-2 pb-6'>
              {navItems.map((item, index) => (
                <NavItem key={index} {...item} />
              ))}
            </ul>
          </nav>

          {/* SearchBar */}
          <div className='px-3 lg:w-3/5 m-auto pb-40'>
            {(location.pathname !== "/myprofile" && location.pathname !== "/news" && location.pathname !== "/admin" && location.pathname !== "/api" && location.pathname !== "/planet") && (
              <SearchBar onPlaceSelected={handlePlaceSelected} />
            )}

            <Routes>
              <Route path="/tonightforecast" element={<Tab1 />} />
              <Route path="/dailyforecast" element={<Tab3 />} />
              <Route path="/news" element={<News />} />
              <Route path="/api" element={<ApiInfo />} />
              <Route path="/api_doc" element={<Api_doc />} />
              <Route path="/news/:title" element={<ArticleDetail />} />
              <Route path="/myprofile" element={<Tab4 />} />
              <Route path="/planet" element={<PlanetTab />} />
              {userData && userData.role === 'admin' && <Route path="/admin" element={<Admin />} />}
              <Route index path="/" element={<Navigate to="/tonightforecast" />} />
              <Route path="/redirection" element={<Subscriptionsucess />} />

              {/* FOOTER */}
              <Route path="/about" element={<About />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              {/* END_FOOTER */}
            </Routes>
          </div>

          {!isNative && <Footer />}
        </>
      )}
    </div>



  );
}






export default App;
